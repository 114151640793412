<template>
  <div>
    <div class="digitalTwin_page">
      <div class="banner_wrap">
        <p class="title fadeInLeft slow animated wow">数字孪生解决方案</p>
        <!-- <p class="title_m fadeInLeft slow animated wow">建筑信息化全栈式解决方案</p>
          <p class="title_m fadeInLeft slow animated wow">助力企业数字化转型</p>
          <p class="des fadeInRight slow animated wow">为建筑企业提供三维扫描硬件，建模、测量、数据智能分析软件和云服务的全栈式产品和服务</p> -->
      </div>
      <div class="menu_wrap">
        <div
          class="menu_item"
          :class="{ active: active === item.type }"
          v-for="(item, index) in menu"
          :key="index"
          @click="changeMenu(item.type)"
        >
          <img :src="item.img" alt="" srcset="" />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="des_wrap">
        <div class="des_content">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="img_warp fadeInLeft slow animated wow">
              <img class="left" :src="item.img" alt="" />
              <!-- <img
                class="play_icon"
                src="../assets/digitalTwin/play.png"
                alt=""
                srcset=""
              /> -->
            </div>
            <div class="right fadeInRight slow animated wow">
              <div class="title">{{ item.title }}</div>
              <p class="des" style="margin-bottom: 0.13rem">
                {{ item.des }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { WOW } from "wowjs";
const all = [
  {
    title: "房屋旧改项目",
    des: "对于老旧建筑的改造过程中，短时间内获取老旧建筑的全面数据，解决了因图纸遗失以及传统的测量导致准确度和效率的问题。通过扫描计算生成的高精度三维模型，为老旧建筑的设计、规划、改造提供便捷的数据支持。",
    img: require("@/assets/digitalTwin/1.png"),
    path: "https://3dtours.unre.com/view/320d942e-6d7d-4745-aeab-c6519b96edd3",
    type: "6",
  },
  {
    title: "室内大平层三维可视化",
    des: "经过设计模型校对，消除设计与施工现场的误差，提供可用可交 付的施工方案。一体化的设备优势，避免了人工测量的局限性， 实现了装修",
    img: require("@/assets/digitalTwin/2.png"),
    path: "https://3dtours.unre.com/view/43ad0aea-8934-4f00-81dd-d8b71f44b566",
    type: "6",
  },
  {
    title: "某数字孪生变电站项目",
    des: "在变电站的三维数字化中，三维激光扫描技术被用于建立变电站的数字孪生模型。通过1:1真彩模型的三维展示平台，可以直观展示变电站室内室外的环境信息。配合物联网感知终端、监控等手段，可以实现风险预控、设备故障预测、管控一体化等功能，减少人员工作量，方便电站运行维修时提取信息，提升效率和精细化管理水平。",
    img: require("@/assets/digitalTwin/3.png"),
    path: "https://3dtours.unre.com/view/8a3818db-d33d-4e41-9495-d3ea6a69be48",
    type: "2",
  },
];
export default {
  name: "UCL360",
  data() {
    return {
      isMobile: false,
      menu: [
        {
          name: "全部",
          type: "0",
          img: require("@/assets/digitalTwin/i0.png"),
        },
        {
          name: "石油炼化",
          type: "1",
          img: require("@/assets/digitalTwin/i1.png"),
        },
        {
          name: "电力",
          type: "2",
          img: require("@/assets/digitalTwin/i2.png"),
        },
        {
          name: "农业",
          type: "3",
          img: require("@/assets/digitalTwin/i3.png"),
        },
        {
          name: "交通与物流",
          type: "4",
          img: require("@/assets/digitalTwin/i4.png"),
        },
        {
          name: "智慧城市",
          type: "5",
          img: require("@/assets/digitalTwin/i5.png"),
        },
        {
          name: "建筑设计",
          type: "6",
          img: require("@/assets/digitalTwin/i6.png"),
        },
      ],
      list: [],
      active: "0",
    };
  },
  components: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
    this.filterList("0");
  },
  methods: {
    open(data) {
      window.open(data);
    },
    changeMenu(data) {
      this.active = data;
      this.filterList(data);
    },
    filterList(type) {
      let arr = [];
      all.forEach((element) => {
        if (element.type === type || type === "0") {
          arr.push(element);
        }
      });
      this.list = arr;
    },
  },
};
</script>
  
  <style lang="less">
.digitalTwin_page {
  background: #fff;
  overflow: hidden;
  .banner_wrap {
    background-image: url(../assets/digitalTwin.png);
    background-size: cover;
    width: 10rem;
    height: 2.88rem;
    padding-top: 1.36rem;
    .title {
      display: block;
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.21rem;
    }
    .title_m {
      display: none;
    }
    .des {
      color: #e6e6e6;
      font-size: 0.13rem;
    }
  }
  .img_warp {
    position: relative;
    cursor: pointer;
    .play_icon {
      width: 0.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .menu_wrap {
    padding: 0.26rem 1.06rem;
    display: flex;
    .menu_item {
      min-width: 0.72rem;
      background: #ffffff;
      border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
      border: 0.01rem solid #cbcbcb;
      color: #808080;
      padding: 0.02rem 0.03rem;
      margin-right: 0.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        // width: 0.1rem;
        height: 0.1rem;
        margin-right: 0.05rem;
      }
      &.active {
        color: white;
        background-color: #02aeec;
      }
    }
  }
  .des_wrap {
    .des_content {
      .item {
        padding: 0.25rem 1.05rem;
        width: 100%;
        background-color: #edf1f7;
        display: flex;
        justify-content: space-between;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(even) {
          background-color: #ffffff;
        }
      }
      .left {
        width: 3.46rem;
      }
      .right {
        width: 3.4rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          border-left: 0.02rem solid #02aeec;
          height: 0.17rem;
          margin-bottom: 0.15rem;
          margin-top: 0.25rem;
          line-height: 0.17rem;
          padding-left: 0.06rem;
          color: #333333;
          font-weight: 600;
          font-size: 0.15rem;
        }

        .des {
          line-height: 0.16rem;
          color: #666666;
          font-size: 0.084rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .Solution_page {
    .breadcrumb_wrap {
      display: none;
    }
    .img_warp {
      .play_icon {
        width: 1.5rem;
      }
    }
    .menu_wrap {
      padding: 0.26rem 1.06rem;
      display: flex;
      overflow-x: scroll;
      .menu_item {
        min-width: 2.72rem;
        background: #ffffff;
        border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
        border: 0.01rem solid #cbcbcb;
        color: #808080;
        padding: 0.2rem 0.03rem;

        img {
          // width: 0.1rem;
          height: 0.5rem;
          margin-right: 0.05rem;
        }
      }
    }
    .banner_wrap {
      background-image: url(../assets/digitalTwin/m_bg.png);
      background-size: cover;
      width: 10rem;
      padding-top: 6.31rem;
      .title {
        display: none;
      }
      .title_m {
        display: block;
        margin-bottom: 0.28rem;
        color: #fcfcfc;
        font-size: 0.43rem;
        &:last-child {
          margin-bottom: 0.38rem;
        }
      }
      .des {
        color: #e6e6e6;
        width: 9rem;
        font-size: 0.32rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    .des_wrap {
      padding: 0 0.43rem 0.61rem;
      background: #fff;
      .des_content {
        width: 100%;
        .item {
          padding: 0.55rem 0.34rem;
          background: #edf1f7;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.29rem;
        }
        .left {
          width: 100%;
          margin-bottom: 0.43rem;
        }
        .right {
          width: 100%;
          text-align: left;

          .title {
            border-left: 0.07rem solid #02aeec;
            height: 0.63rem;
            margin-bottom: 0.4rem;
            line-height: 0.63rem;
            padding-left: 0.06rem;
            color: #333333;
            font-weight: 600;

            font-size: 0.43rem;
          }

          .des {
            line-height: 0.48rem;
            color: #666666;

            font-size: 0.32rem;
          }
        }
      }
    }
  }
}
</style>
  